<template>
    <div class="text-center">
		<v-dialog
			v-model="showImportTemplate"
			transition="dialog-top-transition"
			persistent
			width="900"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
            <v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                    <v-col 
                        cols="6"
                    >
                        Import Template
                    </v-col>
                </v-card-title>

                <v-card-text style="margin-top: 20px;">

                    <v-row>
                        <v-col
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Templates</label>
                            <v-combobox
                                v-model="templateSelected"
                                :items="listTemplate"
                                item-text="nameWithUser"
                                item-value="id"
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions 
                    class="justify-center"
                >
                    <v-tooltip 
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                style="background-color: var(--color__red) !important; color: #ffffff !important"
                                @click="cancel()"
                            >
                                Cancel
                            </v-btn>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                    <v-tooltip 
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                v-on="on"
                                :loading="loading"
                                @click="confirmImport()"
                            >
                                Import
                            </v-btn>
                        </template>
                        <span>Import the template</span>
                    </v-tooltip>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";

	export default {

        components: {
            ActionDialog: ActionDialog
        },

        mixins: [Helpers],

        props: {

            idAux: {
                default: 0
            },

			showImportTemplate: {
				default: false
			},
		},
		
        data: () => ({

            validForm: true,
			loading: false,

			templateSelected: null,
            listTemplate: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
		}),

        watch: {

            async "idAux"() {
                await this.getTemplates()
            },
        },

        methods: {

            async getTemplates() {
                this.listTemplate = await this.$store.dispatch("templateModule/List", true);
            },

            async clearFields() {
                this.templateSelected = null;
            },

            async cancel() {
                this.$emit('update:showImportTemplate', false);
            },

            confirmImport() {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will IMPORT this template, confirm your decision?`,
                    methodConfirm: this.import,
                    params: 0,
                };
            },

            async import() {

                this.showLoading();

                this.$emit('methodConfirmToCall', this.templateSelected);
                this.cancel();

                this.hideLoading();
            },
        }
    };
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

</style>